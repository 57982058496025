@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: 1.7rem;
}

body {
  background: #232833;
  color: #dbdce4;
  text-align: center;
}

.bottom-mobile > img {
  border-top-left-radius: 15px;
}

.bottom-desktop {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: #232a33;
  z-index: 9999;
  flex-direction: column;
  flex-wrap: nowrap;

}



.ui {
  position: relative;
  border-radius: 40px;
  padding: 0 20px;
}

/* siders */

.outer {
  width: 100%;
  overflow: hidden;
  margin: 10px auto;
  display: flex;
  min-height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-container {
  flex-basis: 1440px;
}

.sider-fsc-container {
  width: 240px;
}

.sider-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;
}
.sider {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 8;
  flex-direction: row;
  flex-wrap: nowrap;
}
.sider .majorsim-ac {
  z-index: -99;
}
.adv-img-vert {
  margin: auto;
}
.adv-vert {
  flex-direction: column;
}

.hide-siders {
  position: absolute;
  right: 8px;
  top: 5px;
}


@media only screen and (max-width: 899px) {
  .sider {
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  .right-sider {
    right: 0;
  }
  .left-sider {
    left: 0;
  }
}

/*
@media only screen and (max-width: 800px) {
  .sider {
    display: none;
  }
}
@media only screen and (min-width: 801px) {
  .sider {
    width: 10vw;
  }
  .right-sider {
    right: 0;
  }
  .left-sider {
    left: 0;
  }
  .adv-img-vert {
    max-width: 10vw;
    margin: auto;
  }
}

@media only screen and (min-width: 1401px) {
  .sider {
    width: 140px;
  }
  .adv-img-vert {
    max-width: 140px;
    margin: auto;
  }
}*/

@media only screen and (min-width: 1600px) {
  .sider {
    width: 160px;
    height: 800px;
  }
  .right-sider {
    right: calc(25% - 1600px / 4);
  }
  .left-sider {
    left: calc(25% - 1600px / 4);
  }
}






.dynamic-padding {
  padding-bottom: 150px;
}

.adv-img {
  flex: 1;
  flex-shrink: 0;
  display: inline-block;
}

.adv-logo-inline {
  display: flex;
}
.adv-logo-inline-left {
  flex: 1;
  text-align: right;
  margin-right: 10px;
}
.adv-logo-inline-right {
  flex: 2;
  text-align: left;
  margin-left: 10px;
}
.adv-logo-inline-left img {
  display: inline-block;
  height: 50px;
}


@media only screen and (max-width: 1920px) {
  .alt-ads {
    display: none;
    flex: 0;
  }
}

/* desktop site */
@media only screen and (min-width: 801px) {
  .mobile-menu {
    display: none;
  }
  .adv-logo-inline-left {
    border-right: 1px solid rgba(155, 155, 155, 0.4);
    padding-right: 20px;
  }
}

/* mobile site */
@media only screen and (max-width: 800px) {
  .google-adv-img {
    display: none;
  }
  .hide-on-mobile {
    display: none;
  }
  .bottom-desktop {
    flex-direction: column;
  }
  .desktop-menu {
    display: none;
  }
  .adv-logo-inline {
    flex-direction: column;
  }
  .adv-logo-inline-left {
    text-align: center;
    margin: 0 auto;
  }
  .adv-logo-inline-left img {
    height: 32px;
  }
  .adv-logo-inline-right {
    text-align: center;
    margin: 10px auto;
  }
}

.majorsim-ac {
  min-height: 80px;
  background-size: contain;
  background-position-x: center;
}


.hyperlink {
  color: #8d91bf;
}

.hyperlink:hover {
  color: #bec1da;
}


.page-container {
  flex-grow: 1;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.sponsored {
}
.title-container {
  margin-bottom: 24px;
}

.title-outer-container {
  margin-bottom: 24px;
}


.main-container {
  max-width: 100vw;
  padding: 40px;
  user-select: none;
}

@media only screen and (max-width: 600px) {
  .main-container {
    padding: 0;
  }
}

.title {
  font-weight: 600;
  font-size: 35px;
}

@media only screen and (max-width: 600px) {
  .title {
    font-weight: 600;
    font-size: 24px;
  }
}

h3.title {
  font-weight: 600;
  font-size: 28px;
}

.round-title {
  margin: 15px 0;
  border-bottom: 1px solid #6d72ac;
  text-align: left;
}

.team-box-split.adv {
  background: #2185d0;
}

.team-box-split.elim {
  background: #db2828;
}

.team {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  width: 80px;
}

.team-box {
  text-align: center;
}

.team.two {
  width: 160px;
}

.team-box.up {
  text-align: center;
  width: 100%;
  height: 32px;
}

.team-box.med {
  text-align: center;
  width: 100%;
  height: 64px;
  line-height: 58px;
  margin-top: -1px;
  display: flex;
}

.team-box.down {
  text-align: center;
  width: 100%;
  height: 33px;
  margin-top: -1px;
  display: flex;
}

.team-box-split {
  flex: 1;
  text-align: center;
}

.team.two .team-box-split:not(:first-child) {
  margin-left: -1px;
}

.team .b  {
  border: 1px solid #fff;
}
.team .team-box-text {
  color: #fff;
  position: relative;
}

.team.advanced .b  {
  border: 1px solid #ffc40a;
}
.team.advanced .team-box-text {
  color: #ffc40a;
}

.team.elimination-stage .b  {
  border: 1px solid #ffc40a;
}
.team.elimination-stage .team-box-text {
  color: #ffc40a;
}

.team.legends .b  {
  border: 1px solid #00ff00;
}
.team.legends .team-box-text {
  color: #00ff00;
}

.team.challengers .b  {
  border: 1px solid #abff00;
}
.team.challengers .team-box-text {
  color: #abff00;
}

.team.opening-stage .b  {
  border: 1px solid #abff00;
}
.team.opening-stage .team-box-text {
  color: #abff00;
}

.team.contenders .b  {
  border: 1px solid #ffcd00;
}
.team.contenders .team-box-text {
  color: #ffcd00;
}

.team.champion .b  {
  border: 1px solid #c9b037;
}
.team.champion .team-box-text {
  color: #c9b037;
}

.team.runner-up .b  {
  border: 1px solid #d7d7d7;
}
.team.runner-up .team-box-text {
  color: #d7d7d7;
}

.team.semifinalist .b  {
  border: 1px solid #A77044;
}
.team.semifinalist .team-box-text {
  color: #A77044;
}

.team.quarterfinalist .b  {
  border: 1px solid #ffccaa;
}
.team.quarterfinalist .team-box-text {
  color: #ffccaa;
}

.team.eliminated .b {
  border: 1px solid #8e8e8e;
}
.team.eliminated .team-box-text {
  color: #8e8e8e;
}

.team-box-split {
  position: relative;
}

.team-box-split.tb-win {
  background: #11cfff;
}

.team-box-split.tb-lose {
  background: #0b7b94;
}

.team-box-split.win {
  background: #6d72ac;
}

.team-box-split.locked::after {
  content: '';
  width: 24px;
  height: 24px;
  display: block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABB0lEQVRIieWUMWsCQRCFv5WASWFAW5uYMoUh/yQg+QXXmvwi/4+myokQ7IT0SZEIdnk2c6CLdzp7R1L4YNhd5s17s7Ow8N+Q1Jb0ImkmaW0xk/QsqV1XvC8pVzneJPXrdF6If0gaSepYPEpa7pj4b2JjKcR7B/Jdy0nSOMXg1YpHFZwn40xTDH6suFPBuTbOdxknRAUtYAjcAFfOnjbACliEEH7LDO6BO6dwjPcQwrw4tKLkoKY4wO3uITbwjuUQLqsMGseFg/sAZLafAPkpRZ4bZEDPIjvCTTJIgsdgAnwBn7Y/CZ43yAH3n/PnI9o0oLmnERusGjDY04jfYGHrgBqfXVpfZ4st9DPPr3/ZyIgAAAAASUVORK5CYII=") no-repeat;
  position: absolute;
  bottom: 0;
}

.team-box-split.rs-win {
  background: #2f7e3e!important;
}

.team-box-split.rs-lose {
  background: #333!important;
}

.team-box-split.lose.rs-win {
  background: #113316 !important;
}

.team-box-split.win.rs-lose {
  background: #f58a96 !important;
}

.team-box-split.win:hover {
  background: #868bc4;
}

.team-box-split.lose:hover {
  background: #404471;
}

.team-box-text {
  font-weight: 600;
  font-size: 16px;
  vertical-align: middle;
  line-height: 30px;
}

.team-box-text-sm {
  font-size: 12px;
  vertical-align: middle;
  line-height: 30px;
}

.team-box-text.win {
  color: #3dd94a;
}
.team-box-text.lose {
  color: #f58a96;
}
.team-logo {
  vertical-align: middle;
  display: inline-block;
  max-height: 64px;
}
.stacked-logo {
  position: relative;
}

.team-logo-bg-container {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(35, 41, 77, 0.75);
  border-top-left-radius: 16px;
}

.team-logo-bg {
  width: 32px;
  max-height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
  vertical-align: middle;
  display: inline-block;
}

.team-logo-small {
  vertical-align: middle;
  display: inline-block;
  max-width: 18px;
  max-height: 18px;
  margin: -2px -2px;
}

.descr {
  font-size: 80%;
}

iframe {
  pointer-events: none;
}


.team.decider .b  {
  border: 1px solid #c2572a;
}
.team.decider .team-box-text {
  color: #c2572a;
}


.team.qualified .b  {
  border: 1px solid #40ff50;
}
.team.qualified .team-box-text {
  color: #40ff50;
}
.team.rmr-a .b  {
  border: 1px solid #fc40ff;
}
.team.rmr-a .team-box-text {
  color: #fc40ff;
}
.team.rmr-b .b  {
  border: 1px solid #5eb1f6;
}
.team.rmr-b .team-box-text {
  color: #5eb1f6;
}








@media only screen and (min-width: 1600px) {

  .page-container {
    display: inline-block;
    margin: 4px auto;
  }

}

.region-selector .active.item {
  border-color: #fff;
  border-width: 4px!important;
  color: #fff;
}

.region-selector {
  overflow: scroll auto;
  max-width: 100%;
}

.region-selector::-webkit-scrollbar {
  display: none;
}

img.ui {
  display: block;
}
.ui-image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  background-color: transparent;
}


ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}
ins.adsbygoogle { background: transparent !important; }
