:root {
  color-scheme: dark;
}

.bracketWrapper {
  color-scheme: dark;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
}

.bracket {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rounds {
  display: flex;
  align-items: center; /* Add this line to center-align rounds vertically */
  justify-content: center;
}

.round {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  position: relative;
}

.team {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr auto 0;
  gap: 5px;
  align-items: center;
  text-align: left;
}

.teamWithBuchholtz {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr auto 16px 0;
  gap: 5px;
  align-items: center;
  text-align: left;
}

.teamName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamLogo {
  width: 24px;
  height: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teamLogo img {
  max-width: 28px;
  max-height: 24px;
  padding: 2px;
}

.bracket {
  margin-bottom: 1rem;
}

.roundWidth {
  width: 220px;
}

.finalWidth {
  width: 260px;
}

.teamNomatch {
  display: grid;
  grid-template-columns: 13px 2fr;
  gap: 5px;
  padding: 0 0 0 5px;
  align-items: center;
  text-align: left;
  background: #232833;
}

.match {
  display: grid;
  grid-template-columns: 25px auto;
  font-size: 13px;
  position: relative;
  margin-bottom: 0.3rem;
  user-select: none;
  background: #232833;
}

.noMatch {
  padding: 0 2px;
  display: grid;
  font-size: 13px;
  position: relative;
  gap: 0.1rem;
  margin-bottom: 0.1rem;
  user-select: none;
}

.matchNumber {
  text-align: center;
  grid-row: 1 / span 2;
  align-self: center;
  justify-self: center;

}

.teamRanking {
  text-align: center;
  align-self: center;
  justify-self: center;
}

.scores {
  display: flex;
}

.score {
  flex: 1;
  text-align: center;
  width: 18px;
}

.buchholtz {
  text-align: center;
}

.pickChip {
  width: 0;
}
